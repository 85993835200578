import axios from "axios";
import ServiceSetting from "../settings/ServiceSetting";
import { AccessStorage } from "../storages/AccessStorage";

export class AuthService {
  static login = async (username, password) => {
    let data = new FormData();
    data.set("username", username);
    data.set("password", password);
    data.set("grant_type", "password");
    data.set("client_id", ServiceSetting.authClientId);
    data.set("client_secret", ServiceSetting.authClientSecret);
    data.set("access_type", "integration-recruiter");

    try {
      let resp = await axios.post(
        ServiceSetting.authUrl + "connect/token",
        data
      );
      await AccessStorage.set(resp.data);
      return resp.data;
    } catch (e) {
      return null;
    }
  };
}
