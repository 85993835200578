import React, { Component } from "react";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import MaterialTable from "material-table";
import { UsersService } from "../../services/UsersService";
import { Loading } from "../../components/Loading/Loading";
import Container from "@material-ui/core/Container";
import SimpleDialog from "components/Dialog/SimpleDialog.jsx";
import { AccessStorage } from "../../storages/AccessStorage";

export class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Name", field: "nameFull" },
        { title: "E-mail", field: "email" },
        { title: "Password", field: "password" }
      ],
      data: [],
      pageSize: 5,
      loading: true,
      dialog: false
    };
  }

  componentDidMount() {
    this.updateUsers().then(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <>
        <GridContainer>
          <Loading hide={this.state.loading} />
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary" style={styles.cardBg}>
                <h4 style={styles.cardTitle}>Users</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  style={{ boxShadow: "none" }}
                  options={{
                    pageSize: this.state.pageSize,
                    sorting: false,
                    showTitle: false,
                    draggable: false,
                    actionsColumnIndex: 4
                  }}
                  columns={this.state.columns}
                  data={this.state.data}
                  editable={{
                    isDeleteHidden: rowData =>
                      rowData.userId === AccessStorage.getUser().userId,
                    onRowAdd: newData =>
                      new Promise((resolve, reject) =>
                        setTimeout(() => {
                          UsersService.save(newData)
                            .then(() => {
                              this.updateUsers().then(resolve);
                            })
                            .catch(() => {
                              this.setState({ loading: false, dialog: true });
                              resolve();
                            });
                        }, 1000)
                      ),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) =>
                        setTimeout(() => {
                          UsersService.save(newData)
                            .then(() => {
                              this.updateUsers().then(resolve);
                            })
                            .catch(() => {
                              this.setState({ loading: false, dialog: true });
                              resolve();
                            });
                        }, 1000)
                      ),
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) =>
                        setTimeout(() => {
                          let data = this.state.data;
                          const index = data.indexOf(oldData);
                          let userId = data[index]["userId"];
                          UsersService.remove(userId).then(() => {
                            this.updateUsers().then(resolve);
                          });
                        }, 1000)
                      )
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <SimpleDialog
          title={"Users"}
          description={"Este e-mail já está cadastrado!"}
          isShowing={this.state.dialog}
          hide={() => {
            this.setState({ dialog: false });
          }}
          onClose
          open
          hideTextButton={"Fechar"}
        />
      </>
    );
  }

  async updateUsers() {
    let users = await UsersService.getUsers();
    await this.setState({ data: users });
    await this.setState({ pageSize: users?.length ?? 1 });
  }
}

export const styles = {
  cardBg: {
    background: "linear-gradient(60deg, #74BF44, #589232)"
  },
  cardTitle: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};
