import axios from "axios";
import ServiceSetting from "../settings/ServiceSetting";
import { AccessStorage } from "../storages/AccessStorage";

export class StudentService {
  static get = async (startDate, endDate) => {
    try {
      let access = await AccessStorage.get();
      let currentUser = await AccessStorage.getUser();
      let resp = await axios.post(
        `${ServiceSetting.adminUrl}admin/students/${currentUser.addonGuid}`,
        {
          startDate: startDate,
          endDate: endDate
        },
        {
          headers: {
            Authorization: `${access["token_type"]} ${access["access_token"]}`
          }
        }
      );
      return resp?.data ?? [];
    } catch (e) {
      return [];
    }
  };

  static generateExcel = async (startDate, endDate) => {
    try {
      let access = await AccessStorage.get();
      let currentUser = await AccessStorage.getUser();
      let resp = await axios.post(
        `${ServiceSetting.adminUrl}admin/students/generate-excel/${currentUser.addonGuid}`,
        {
          startDate: startDate,
          endDate: endDate
        },
        {
          headers: {
            Authorization: `${access["token_type"]} ${access["access_token"]}`
          }
        }
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static generateResult = async (language, assessmentGuid) => {
    try {
      let access = await AccessStorage.get();
      let currentUser = await AccessStorage.getUser();
      let resp = await axios.post(
        `${ServiceSetting.adminUrl}assessment/reports/${currentUser.addonGuid}/generate-result`,
        {
          assessmentGuid: assessmentGuid,
          language: language,
          ThomasReport: [110, 113]
        },
        {
          headers: {
            Authorization: `${access["token_type"]} ${access["access_token"]}`
          }
        }
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static generatePrint = async reportUrls => {
    try {
      let access = await AccessStorage.get();
      let resp = await axios.post(
        `${ServiceSetting.adminUrl}admin/students/generate-print`,
        reportUrls,
        {
          headers: {
            Authorization: `${access["token_type"]} ${access["access_token"]}`
          }
        }
      );
      return ServiceSetting.defaultUrl + "temp/" + resp.data;
    } catch (e) {
      return null;
    }
  };
}
