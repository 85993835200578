import * as React from "react";
import { useEffect, useState } from "react";

import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import MaterialTable from "material-table";
import { Loading } from "../../components/Loading/Loading";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import { StudentService } from "services/StudentService.jsx";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

export function Candidates() {
  const [selectedDateStart, setSelectedDateStart] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const [print, setPrint] = useState(false);
  const [language, setLanguage] = useState(null);
  const [assessmentGuids, setaAssessmentGuids] = useState([]);
  const [reportUrls, setaReportUrls] = useState([]);

  let handleGenerateExcel = async () => {
    setLoading(true);
    try {
      await StudentService.generateExcel(selectedDateStart, selectedDateEnd)
        .then(response => {
          let file = require("file-saver");
          const blob = new Blob([response], {
            type: "application/octet-stream"
          });
          file.saveAs(blob, "thomas_candidates.csv");
        })
        .catch(() => {});
    } catch (err) {}
    setLoading(false);
  };

  let handleGenerateResult = async () => {
    setLoading(true);

    var promises = [];
    for (let assessmentGuid of assessmentGuids) {
      promises.push(StudentService.generateResult(language, assessmentGuid));
    }

    await Promise.all(promises).then(async () => {
      await onRefresh();
    });

    setLoading(false);
  };

  let handleGeneratePrint = async () => {
    setLoading(true);
    if (reportUrls) {
      await StudentService.generatePrint(reportUrls).then(data => {
        window.open(data, "_blank");
      });
    } else {
      window.alert(
        "You must select at least one student with an available result!"
      );
    }
    setLoading(false);
  };

  let fetchCandidates = async () => {
    await StudentService.get(selectedDateStart, selectedDateEnd).then(data => {
      if (!!data) {
        setCandidates(data ?? []);
      } else {
        setCandidates([]);
      }
    });
  };

  let formatDate = date => {
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    let dt = new Date(date);
    const day = dt.getDate();
    const monthIndex = dt.getMonth();
    const year = dt.getFullYear();
    return day + " " + monthNames[monthIndex] + " " + year;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchCandidates();
      setLoading(false);
    })();
  }, [selectedDateStart, selectedDateEnd]);

  const onRefresh = async () => {
    await fetchCandidates();
    setSelected(false);
    setaAssessmentGuids([]);
  };

  const handleSelectRowChange = rows => {
    setSelected(
      rows.length > 0 && !!rows?.every(a => a.reportPdf === undefined)
    );
    setPrint(rows.length > 0 && !!rows?.every(a => a.reportPdf !== undefined));
    setaAssessmentGuids(rows?.map(a => a.assessmentGuid));
    setaReportUrls(rows?.map(a => a.reportPdf));
  };

  const handleSelectLanguageChange = event => {
    event.persist();
    const target = event.target;
    const value = target.value;
    setLanguage(value);
  };

  return (
    <GridContainer>
      <Loading hide={loading} />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" style={styles.cardBg}>
            <h4 style={styles.cardTitle}>Candidates</h4>
          </CardHeader>
          <CardBody>
            <Box style={styles.dates}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <GridContainer container spacing={3}>
                  <Grid item xs={12} sm={12} md={3}>
                    <KeyboardDatePicker
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-start"
                      label="Date Start"
                      name="dateStart"
                      value={selectedDateStart}
                      onChange={setSelectedDateStart}
                      style={styles.dateFull}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <KeyboardDatePicker
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-end"
                      label="Date End"
                      name="dateEnd"
                      value={selectedDateEnd}
                      onChange={setSelectedDateEnd}
                      style={styles.dateFull}
                    />
                  </Grid>
                  {selected && (
                    <>
                      <Grid item xs={12} sm={12} md={3}>
                        <FormControl style={styles.formController}>
                          <InputLabel id="selectLanguage">Language</InputLabel>
                          <Select
                            labelId="selectLanguage"
                            value={language}
                            onChange={handleSelectLanguageChange}
                          >
                            <MenuItem value="28">
                              English (United Kingdom)
                            </MenuItem>
                            <MenuItem value="10">French</MenuItem>
                            <MenuItem value="31">Italian</MenuItem>
                            <MenuItem value="35" checked>
                              Portuguese (Brazil)
                            </MenuItem>
                            <MenuItem value="36">
                              Portuguese (Portugal)
                            </MenuItem>
                            <MenuItem value="6">Spanish (Europe)</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleGenerateResult}
                          style={styles.buttonAction}
                        >
                          Generate Report
                        </Button>
                      </Grid>
                    </>
                  )}
                  {print && (
                    <Grid item xs={12} sm={12} md={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGeneratePrint}
                        style={styles.buttonAction}
                      >
                        Print
                      </Button>
                    </Grid>
                  )}
                </GridContainer>
              </MuiPickersUtilsProvider>
            </Box>
            <MaterialTable
              data={candidates}
              columns={[
                { title: "First Name", field: "firstName" },
                { title: "Last Name", field: "lastName" },
                { title: "ExternalId", field: "externalId" },
                { title: "E-mail", field: "email" },
                { title: "Profile", field: "profile" },
                { title: "Status", field: "status" },
                {
                  title: "Register",
                  field: "register",
                  type: "date",
                  render: rowData => <span>{formatDate(rowData.register)}</span>
                },
                {
                  title: "Invite",
                  field: "openInvite",
                  render: rowData => (
                    <a
                      href={rowData.openInvite}
                      target={"_blank"}
                      style={styles.link}
                    >
                      {" "}
                      Open invite{" "}
                    </a>
                  )
                },
                {
                  title: "Report",
                  field: "openReport",
                  render: rowData => (
                    <a
                      href={rowData.openReport}
                      target={"_blank"}
                      style={styles.link}
                    >
                      {" "}
                      Open report{" "}
                    </a>
                  )
                },
                {
                  title: "Result",
                  field: "reportPdf",
                  render: rowData => (
                    <span>
                      {rowData.reportPdf !== undefined ? (
                        <a
                          href={rowData.reportPdf}
                          target={"_blank"}
                          style={styles.link}
                        >
                          {" "}
                          PDF{" "}
                        </a>
                      ) : (
                        "--"
                      )}
                    </span>
                  )
                }
              ]}
              title="Export Candidates"
              style={styles.table}
              options={{
                pageSize: 5,
                sorting: true,
                showTitle: false,
                draggable: false,
                actionsColumnIndex: 4,
                showTextRowsSelected: false,
                rowStyle: rowData => ({
                  backgroundColor: rowData.tableData.checked
                    ? "#E5E7E9"
                    : "#FFF"
                }),
                exportButton: {
                  csv: true,
                  pdf: false
                },
                exportCsv: async (columns, data) => {
                  if (
                    window.confirm(
                      "Do you really want to generate an excel for these candidates?"
                    )
                  ) {
                    await handleGenerateExcel();
                  }
                }
                //selection: true
              }}
              onSelectionChange={rows => handleSelectRowChange(rows)}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export const styles = {
  cardBg: {
    background: "linear-gradient(60deg, #74BF44, #589232)"
  },
  cardTitle: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  buttonAction: {
    backgroundColor: "#ab47bc",
    marginTop: "27px",
    marginBottom: "8px",
    zIndex: 2,
    position: "relative",
    width: "100%"
  },
  grid: {
    margin: "0 0 0 0 !important"
  },
  dates: {
    float: "none",
    zIndex: 2,
    position: "relative",
    padding: "0 15px"
  },
  dateFull: {
    width: "100%"
  },
  table: {
    boxShadow: "none",
    zIndex: 1,
    position: "relative"
  },
  link: {
    color: "#589232"
  },
  formController: {
    width: "100%",
    marginTop: "16px",
    marginBottom: "8px",
    zIndex: 2,
    position: "relative"
  }
};
